import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeHtml',
    standalone: false
})
export class SanitizeHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(v: string): SafeHtml {
        const newValue = this.sanitizer.bypassSecurityTrustHtml(v);
        console.log(newValue);
        return this.sanitizer.bypassSecurityTrustHtml(v);
    }
}
