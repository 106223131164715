import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-express',
    templateUrl: './express.component.html',
    styleUrls: ['./express.component.scss'],
    standalone: false
})
export class ExpressComponent implements OnInit {

    constructor () { }

    ngOnInit () {

    }

}
