import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomerAddress } from '../../../_models/';
import { AuthenticationService, SolentExpressService } from '../../../_services/';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';

@Component({
    selector: 'app-sidebar-express',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarExpressComponent implements OnInit, OnDestroy {
    deliveryAddress: CustomerAddress = null;
    invoiceAddress: CustomerAddress = null;

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private authService: AuthenticationService,
        private expressService: SolentExpressService) { }


    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {

        this.expressService.getInvAddress(this.authService.returnCustomerNo())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(address => { this.invoiceAddress = address; });

        this.expressService.getDefaultAddress(this.authService.returnCustomerNo())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(address => { this.deliveryAddress = address; });
    }

}
