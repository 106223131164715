import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';

@Component({
    selector: 'app-login-express',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class ExpressLoginComponent implements OnInit, OnDestroy {
    loading = false;
    returnUrl: string;
    userLoginForm: UntypedFormGroup;
    hide = true;
    formError;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private fb: UntypedFormBuilder,
        private title: Title) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.title.setTitle('Solent Wholesale | User Login');
        this.userLoginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.params.returnUrl || '/';
    }

    login() {
        this.loading = true;
        const username = this.userLoginForm.controls.username.value;
        const password = this.userLoginForm.controls.password.value;
        this.authenticationService.login(username, password)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.formError = 'Username/Password Incorrect';
                    this.loading = false;
                });
    }

}
