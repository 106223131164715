import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Orders } from '../../../_models/';
import { AuthenticationService, SolentExpressService } from '../../../_services/';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss'],
    standalone: false
})
export class OrderHistoryComponent implements OnInit, OnDestroy {
    orders: Orders[];
    staffMember = false;
    faTimes = faTimes;
    faCheck = faCheck;
    orderID: number = null;
    searchForm = new UntypedFormGroup({
        accountNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(10)]),
    });
    public loading = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private authService: AuthenticationService,
        private expressService: SolentExpressService,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: Document) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.getOrders(null);
        this.staffMember = this.authService.staffTest();

        this.searchForm.get('accountNumber').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(accountNo => {
                this.getOrders(accountNo);
            });
    }

    getOrders(customerNo) {
        if (customerNo === null) {
            this.expressService.getRecentOrders(this.authService.returnCustomerNo())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(orders => { this.orders = orders; });
        } else {
            this.expressService.getRecentOrders(customerNo)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(orders => { this.orders = orders; });
        }

    }

    buildHostedPayment(order: Orders) {
        const amount = order.fullTotal;
        this.orderID = order.id;
        // Now we need to get the value as pence
        const amountPence = Math.round(Number(amount) * 100);
        const custEmail = '';
        const transRef = 'expressOrder-' + order.id.toString();
        this.makeHostedPayment(
            amountPence,
            transRef,
            custEmail);
    }

    makeHostedPayment(
        paymentAmount: number,
        email: string,
        customerOrderRef: string,
    ) {
        this.loading = true;
        // Use the free text "payment" here to signify user generated payment
        // if using in the express checkout, we would use the order number
        const paymentObject = this.expressService.buildHostedPaymentObject(
            paymentAmount, customerOrderRef, email, this.orderID);
        // Now we need to sign the object
        this.expressService.generateAPISignature(paymentObject)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                signature => this.processHostedPayment(signature, paymentObject));
    }

    processHostedPayment(signature, paymentObject: any) {
        if (isPlatformBrowser(this.platformId)) {
            const form = this.document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', 'https://payment.card-saver.co.uk/paymentform/');
            // use _self to redirect in same tab, _blank to open in new tab
            // form.setAttribute('target', '_blank');
            form.setAttribute('target', '_self');
            // Add all the data to be posted as Hidden elements
            console.log(paymentObject.callbackURL);
            form.appendChild(this.createHiddenElement('action', paymentObject.action));
            form.appendChild(this.createHiddenElement('amount', paymentObject.amount));
            form.appendChild(this.createHiddenElement('redirectURL', paymentObject.redirectURL));
            form.appendChild(this.createHiddenElement('customerEmail', paymentObject.customerEmail));
            form.appendChild(this.createHiddenElement('countryCode', paymentObject.countryCode));
            form.appendChild(this.createHiddenElement('currencyCode', paymentObject.currencyCode));
            form.appendChild(this.createHiddenElement('formResponsive', paymentObject.formResponsive));
            form.appendChild(this.createHiddenElement('merchantName', paymentObject.merchantName));
            form.appendChild(this.createHiddenElement('merchantData', paymentObject.merchantData));
            form.appendChild(this.createHiddenElement('merchantWebsite', paymentObject.merchantWebsite));
            form.appendChild(this.createHiddenElement('merchantID', paymentObject.merchantID));
            form.appendChild(this.createHiddenElement('orderRef', paymentObject.orderRef));
            form.appendChild(this.createHiddenElement('type', paymentObject.type));
            form.appendChild(this.createHiddenElement('signature', signature));

            this.document.body.appendChild(form);
            form.submit();
        }
    }

    private createHiddenElement(name: string, value: string): HTMLInputElement {
        if (isPlatformBrowser(this.platformId)) {
            const hiddenField = this.document.createElement('input');
            hiddenField.setAttribute('name', name);
            hiddenField.setAttribute('value', value);
            hiddenField.setAttribute('type', 'hidden');
            return hiddenField;
        }
    }

}
